import HpButton from '@/componentLibrary/button/Button.vue';
import HpDrawer from '@/componentLibrary/drawer/Drawer.vue';
import { formSetup } from '../formSetup';

export const actionWithDrawer = {
  components: { HpButton, HpDrawer },
  mixins: [formSetup],
  props: {
    selectedRow: {
      type: Object,
    },
    selectedRows: {
      type: Array,
    },
  },
  data() {
    return {
      processing: false,
      pressed: false,
      drawerResult: null,
    };
  },
  methods: {
    initializeDrawer() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    async openDrawer(reset = true) {
      if (reset) this.initializeDrawer();
      this.pressed = true;
      if (this.open) await this.open();
    },
    closeDrawer() {
      this.pressed = false;
      this.$emit('close');
      if (this.drawerResult && this.drawerResult.updatedRow !== undefined) this.$emit('row-updated', this.drawerResult.updatedRow);
    },
  },
};
