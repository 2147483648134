import axios from 'axios';
import Vue from 'vue';
import { SystemErrorModel } from '@/modules/core/models/systemErrorModel';
import * as msalService from './msalService';

const baseURL = process.env.VUE_APP_API_GATEWAY;
const mock = process.env.VUE_APP_MOCK_API === 'true';

const api = () => {
  const client = axios.create({
    baseURL,
    responseType: 'json',
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      'Mock-Response': mock,
    },
  });

  client.interceptors.request.use(
    async config => {
      const token = (await msalService.getToken(Vue.prototype.$msalInstance)).idToken;

      config.headers.Authorization = `Bearer ${token}`;

      if (config.headers.retries === 2) {
        // retries exceeded reload app.
        await Vue.prototype.$msalInstance.handleRedirectPromise();
        await Vue.prototype.$msalInstance.loginRedirect();
      }
      return config;
    },
    error => Promise.reject(error),
  );

  client.interceptors.response.use(
    response => response,

    async error => {
      if (error.errorCode === 'login_required') {
        await Vue.prototype.$msalInstance.handleRedirectPromise();
        await Vue.prototype.$msalInstance.loginRedirect();
      }
      if (!error.response || error.response.status !== 401) return Promise.reject(error);

      const { config } = error;
      config.headers.retries = +(config.headers.retries || 0) + 1;

      return new Promise((resolve, reject) => {
        client
          .request(config)
          .then(response => {
            resolve(response);
          })
          .catch(err => {
            reject(new SystemErrorModel(err));
          });
      });
    },
  );

  return client;
};

export const apiClient = api();
